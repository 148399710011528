import { Device } from "./type";

export const isActionError = (error: unknown): error is ActionError =>
  typeof error === "object" && error !== null && "statusCode" in error && "code" in error && "message" in error;

export type ActionError = {
  statusCode: number;
  code: string;
  message: string;
};

export type LoginCommand = {
  username: string;
  password: string;
};

export type LoginInfo = {
  token: string;
};

export type GroupListInfo = ReadonlyArray<{
  id: string;
}>;

export type DeviceListCriteria = {
  ids: ReadonlyArray<string>;
};

export type DeviceListInfo = ReadonlyArray<Device>;

export type EditDeviceNameCommand = {
  serial: string;
  name: string;
};

export type RemoveDeviceCommand = {
  serial: string;
};

export type AddDeviceCommand = {
  groupId: string;
  serial: string;
  name: string;
};

export type DeviceHistoryCriteria = {
  serial: string;
};

export type DeviceHistoryInfo = ReadonlyArray<{
  id: number;
  event: "open" | "close";
  datetime: string;
}>;

export type PhoneInfo = ReadonlyArray<
  Readonly<{
    id: number;
    user_id: string;
    phone: string;
    created_datetime: string;
  }>
>;

export type AddPhoneCommand = {
  phone: string;
};

export type RemovePhoneCommand = {
  id: number;
};
