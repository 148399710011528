import { Outlet, Navigate } from "react-router-dom";

import { useUserToken } from "./useUserToken";

export default function AuthRequiredRoute() {
  const userToken = useUserToken();

  if (!userToken) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
}
