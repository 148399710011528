import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import api from "./api";
import kakaoApi from "./kakaoApi";

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    [kakaoApi.reducerPath]: kakaoApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware, kakaoApi.middleware),
});

setupListeners(store.dispatch);
