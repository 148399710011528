import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

import {
  APP_ID,
  APP_KEY,
  DEVICE_ID,
  GATEWAY2_SERIAL_PREFIX,
  GATEWAY3_SERIAL_PREFIX,
  SENSOR2_SERIAL_PREFIX,
  SENSOR3_SERIAL_PREFIX,
} from "./constant";

import type { BatteryLevel, Device, DeviceType, Gateway, Sensor2, Sensor3 } from "./type";
import type * as Action from "./action";
import * as DTO from "./dto";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type DeviceStatus = "normal" | "caution" | "warning" | "maintaining";

export const d2aRecord_BoolNum_boolean = {
  "0": false,
  "1": true,
} satisfies Record<DTO.BoolNum, boolean>;

export const d2aRecord_DeviceType = {
  R: "gateway",
  T: "sensor",
} satisfies Record<DTO.DeviceType, DeviceType>;

export const d2aRecord_DeviceStatus = {
  "1": "normal",
  "2": "caution",
  "3": "warning",
  "5": "maintaining",
} satisfies Record<DTO.DeviceStatus, DeviceStatus>;

export const d2aRecord_BatteryLevel = {
  "0": "empty",
  "1": "low",
  "2": "normal",
} satisfies Record<DTO.BatteryLevel, BatteryLevel>;

export const d2aMapper_ErrorRes_ActionError = (error: FetchBaseQueryError): Action.ActionError => {
  const res = error.data as DTO.ErrorRes;
  return {
    statusCode: error.status as number,
    code: res.error_code,
    message: res.error_message,
  };
};

export const a2dMapper_LoginCommand_PostLoginReq = (commnad: Action.LoginCommand): DTO.PostLoginReq => ({
  app_id: APP_ID,
  app_key: APP_KEY,
  id: commnad.username,
  password: commnad.password,
  device_uuid: DEVICE_ID,
});

export const d2aMapper_PostLoginRes_LoginInfo = (res: DTO.PostLoginRes): Action.LoginInfo => ({
  token: res.user_key,
});

export const d2aMapper_GetServicesRes_GroupListInfo = (res: DTO.GetServicesRes): Action.GroupListInfo =>
  res.filter(DTO.isService).map((service) => ({ id: service.service_id }));

export const d2tMapper_Device = (dto: DTO.Device): Device => {
  const status = d2aRecord_DeviceStatus[dto.device_status.status];
  const device: Omit<Device, "version"> = {
    name: dto.device_name,
    serial: dto.device_sn,
    type: d2aRecord_DeviceType[dto.device_type],
  };

  if (dto.device_sn.startsWith(GATEWAY2_SERIAL_PREFIX) || dto.device_sn.startsWith(GATEWAY3_SERIAL_PREFIX)) {
    const gateway: Gateway = {
      ...device,
      isConnected: status === "normal",
      version: dto.device_sn.startsWith(GATEWAY2_SERIAL_PREFIX) ? 2 : 3,
    };
    return gateway;
  }

  if (dto.device_sn.startsWith(SENSOR2_SERIAL_PREFIX) || dto.device_sn.startsWith(SENSOR3_SERIAL_PREFIX)) {
    const sensor2: Sensor2 = {
      ...device,
      version: 2,
      isDisconnected: status === "maintaining",
      isOpen: d2aRecord_BoolNum_boolean[dto.device_status.open],
      isMute: d2aRecord_BoolNum_boolean[dto.device_status.alarm],
    };

    if (dto.device_sn.startsWith(SENSOR3_SERIAL_PREFIX)) {
      const sensor3: Sensor3 = {
        ...sensor2,
        version: 3,
        batteryLevel: d2aRecord_BatteryLevel[dto.device_status.battery],
      };
      return sensor3;
    }

    return sensor2;
  }

  throw new Error("변경 불가");
};
