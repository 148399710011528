export type ErrorRes = {
  error_code: string;
  error_message: string;
};

export type PostLoginReq = {
  app_id: string;
  app_key: string;
  id: string;
  password: string;
  device_uuid: string;
};

export type PostLoginRes = {
  user_key: string;
};

export type ServiceTotal = {
  service_info_type: "total";
};

export type Service = {
  service_info_type: "service";
  service_id: string;
};

export const isService = (element: GetServicesRes[number]): element is Service =>
  element.service_info_type === "service";

export type GetServicesRes = ReadonlyArray<ServiceTotal | Service>;

export type PostCreateDeviceGroupReq = {
  service_name: string;
};

export type BoolNum = "0" | "1";
export type DeviceType = "T" | "R";
export type DeviceStatus = "1" | "2" | "3" | "5";
export type BatteryLevel = "0" | "1" | "2";

export type Device = {
  device_sn: string;
  device_name: string;
  device_type: DeviceType;
  device_status: {
    status: DeviceStatus;
    open: BoolNum;
    alarm: BoolNum;
    battery: BatteryLevel;
  };
};

export type GetDevicesRes = ReadonlyArray<Device>;

export type PostAddDeviceReq = {
  service_id: string;
  device_sn: string;
  device_name: string;
};

export type PatchRenameDeviceReq = {
  device_name: string;
};
