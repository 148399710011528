import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import * as Action from "./action";
import * as Mapper from "./mapper";
import * as DTO from "./dto";

import { USER_TOKEN_KEY } from "./constant";

const api = createApi({
  reducerPath: "api",
  tagTypes: ["GROUP", "DEVICE", "HISTORY"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NODE_ENV === "production" ? process.env.REACT_APP_API_BASE : "/api",
    prepareHeaders(headers) {
      const userToken = localStorage.getItem(USER_TOKEN_KEY);
      if (userToken) {
        headers.set("Authorization", `Bearer ${userToken}`);
      }
    },
  }),
  endpoints: (build) => ({
    login: build.mutation<Action.LoginInfo, Action.LoginCommand>({
      query: (arg) => ({
        url: "auth/users",
        method: "POST",
        body: Mapper.a2dMapper_LoginCommand_PostLoginReq(arg),
      }),
      transformResponse: Mapper.d2aMapper_PostLoginRes_LoginInfo,
      transformErrorResponse: Mapper.d2aMapper_ErrorRes_ActionError,
    }),
    createGroup: build.mutation<null, void>({
      query: () => ({
        url: "/service",
        method: "POST",
        body: { service_name: "기본그룹" } satisfies DTO.PostCreateDeviceGroupReq,
      }),
      transformErrorResponse: Mapper.d2aMapper_ErrorRes_ActionError,
      invalidatesTags: ["GROUP"],
    }),
    getGroups: build.query<Action.GroupListInfo, void>({
      query: () => ({
        url: "/service",
        method: "GET",
      }),
      transformResponse: Mapper.d2aMapper_GetServicesRes_GroupListInfo,
      transformErrorResponse: Mapper.d2aMapper_ErrorRes_ActionError,
      providesTags: ["GROUP"],
    }),
    addDevice: build.mutation<null, Action.AddDeviceCommand>({
      query: (args) => ({
        url: "/devices",
        method: "POST",
        body: {
          device_name: args.name,
          device_sn: args.serial,
          service_id: args.groupId,
        } satisfies DTO.PostAddDeviceReq,
      }),
      transformErrorResponse: Mapper.d2aMapper_ErrorRes_ActionError,
      invalidatesTags: ["DEVICE"],
    }),
    getDevices: build.query<Action.DeviceListInfo, Action.DeviceListCriteria>({
      queryFn({ ids }, _, __, baseQuery) {
        return Promise.all(
          ids.map((id) =>
            baseQuery({
              url: `/devices/${id}`,
              method: "GET",
            })
          )
        )
          .then((results) => results.flatMap((result) => result.data as DTO.GetDevicesRes))
          .then((res) => res.map(Mapper.d2tMapper_Device))
          .then((devices) => ({
            data: devices,
          }));
      },
      providesTags: ["DEVICE"],
    }),
    editDeviceName: build.mutation<null, Action.EditDeviceNameCommand>({
      query: (args) => ({
        url: `/devices/${args.serial}`,
        method: "PATCH",
        body: { device_name: args.name } satisfies DTO.PatchRenameDeviceReq,
      }),
      transformErrorResponse: Mapper.d2aMapper_ErrorRes_ActionError,
      invalidatesTags: ["DEVICE"],
    }),
    removeDevice: build.mutation<null, Action.RemoveDeviceCommand>({
      query: (args) => ({
        url: `/devices/${args.serial}`,
        method: "DELETE",
      }),
      transformErrorResponse: Mapper.d2aMapper_ErrorRes_ActionError,
      invalidatesTags: ["DEVICE"],
    }),
    getDeviceHistory: build.query<Action.DeviceHistoryInfo, Action.DeviceHistoryCriteria>({
      query: (args) => ({
        url: `/devices/${args.serial}/history`,
        method: "GET",
      }),
      transformErrorResponse: Mapper.d2aMapper_ErrorRes_ActionError,
      providesTags: (_, __, arg) => [{ type: "HISTORY", id: arg.serial }],
    }),
  }),
});

export default api;
