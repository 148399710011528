import { Form, Button, Container, Spinner, Navbar, Alert } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import api from "./api";
import { changeUserToken, useUserToken } from "./useUserToken";

type Inputs = {
  username: string;
  password: string;
};

export default function LoginPage() {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<Inputs>();

  const navigate = useNavigate();

  const [login] = api.endpoints.login.useMutation();

  const userToken = useUserToken();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      const info = await login(data).unwrap();
      changeUserToken(info.token);
      navigate("/");
    } catch {
      alert("로그인 중 에러가 발생했습니다.");
    }
  };

  return (
    <>
      <Navbar bg="light" className="mb-3">
        <Container>
          <Navbar.Brand>Winguard 임시 웹앱</Navbar.Brand>
        </Container>
      </Navbar>

      <Container>
        {userToken && (
          <Alert>
            <Alert.Heading>이미 로그인 중입니다.</Alert.Heading>
            <Alert.Link href="/">여기</Alert.Link>를 누르면 메인화면으로 돌아갑니다.
          </Alert>
        )}
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3" controlId="loginId">
            <Form.Label>아이디</Form.Label>
            <Form.Control type="text" placeholder="아이디를 입력하세요" required {...register("username")} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="loginPassword">
            <Form.Label>비밀번호</Form.Label>
            <Form.Control type="password" placeholder="비밀번호를 입력하세요" required {...register("password")} />
          </Form.Group>
          <div className="d-flex justify-content-end">
            <Button variant="primary" type="submit" disabled={isSubmitting} className="d-flex align-items-center gap-2">
              {isSubmitting && <Spinner size="sm" />}
              로그인
            </Button>
          </div>
        </Form>
      </Container>
    </>
  );
}
