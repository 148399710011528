import { useEffect, useState } from "react";

import { USER_TOKEN_KEY } from "./constant";

const TOKEN_EVENT_TYPE = "token";
const loginEventTarget = new EventTarget();

export const changeUserToken = (token: string | null) => {
  if (token) {
    localStorage.setItem(USER_TOKEN_KEY, token);
  } else {
    localStorage.removeItem(USER_TOKEN_KEY);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }
  loginEventTarget.dispatchEvent(
    new CustomEvent(TOKEN_EVENT_TYPE, {
      detail: token,
    })
  );
};

export const useUserToken = () => {
  const [token, setToken] = useState(localStorage.getItem(USER_TOKEN_KEY));

  useEffect(() => {
    const handler = (event: Event) => {
      setToken((event as CustomEvent<string | null>).detail);
    };
    loginEventTarget.addEventListener(TOKEN_EVENT_TYPE, handler);
    return () => loginEventTarget.removeEventListener(TOKEN_EVENT_TYPE, handler);
  }, []);

  return token;
};
