export type DeviceType = "sensor" | "gateway";
export type BatteryLevel = "empty" | "low" | "normal";

export type Device = {
  serial: string;
  type: DeviceType;
  version: 2 | 3;
  name: string;
};

export type Gateway = Device & {
  isConnected: boolean;
};

export type Sensor2 = Device & {
  isDisconnected: boolean;
  isOpen: boolean;
  isMute: boolean;
};

export type Sensor3 = Sensor2 & {
  batteryLevel: BatteryLevel;
};

export const isGateway = (device: Device): device is Gateway => device.type === "gateway";
export const isSensor2 = (device: Device): device is Sensor2 => device.type === "sensor" && device.version === 2;
export const isSensor3 = (device: Device): device is Sensor3 => device.type === "sensor" && device.version === 3;
