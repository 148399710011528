export const APP_ID = "WjJbLJ9g7q";
export const APP_KEY = "L89TDq383M5SKJwOv3V6";
export const DEVICE_ID = "e749779b-d3d6-4047-b8b4-0ed879e0a93f";

export const USER_TOKEN_KEY = "user-token";

export const SENSOR3_SERIAL_PREFIX = "3E1001";
export const GATEWAY3_SERIAL_PREFIX = "3A1001";
export const SENSOR2_SERIAL_PREFIX = "HS501W";
export const GATEWAY2_SERIAL_PREFIX = "HR501";
