import { DeviceType, BatteryLevel } from "./type";

export const DeviceType_Text = {
  gateway: "수신기" as const,
  sensor: "송신기" as const,
} satisfies Record<DeviceType, string>;

export const BatteryLevel_Text = {
  empty: "교체필요" as const,
  low: "낮음" as const,
  normal: "정상" as const,
} satisfies Record<BatteryLevel, string>;

export const SensorEvent_Text = {
  open: "열림" as const,
  close: "닫힘" as const,
} as const;
