import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import * as Action from "./action";

import { USER_TOKEN_KEY } from "./constant";

const api = createApi({
  reducerPath: "kakaoApi",
  tagTypes: ["PHONE"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NODE_ENV === "production" ? process.env.REACT_APP_KAKAO_API_BASE : "/kakaoApi",
    prepareHeaders(headers) {
      const userToken = localStorage.getItem(USER_TOKEN_KEY);
      if (userToken) {
        headers.set("Authorization", `Bearer ${userToken}`);
      }
    },
  }),
  endpoints: (build) => ({
    list: build.query<Action.PhoneInfo, void>({
      query: () => ({
        url: "/phones",
        method: "GET",
      }),
      providesTags: ["PHONE"],
    }),
    add: build.mutation<null, Action.AddPhoneCommand>({
      query: (args) => ({
        url: "/phones",
        body: args,
        method: "POST",
      }),
      invalidatesTags: ["PHONE"],
    }),
    remove: build.mutation<null, Action.RemovePhoneCommand>({
      query: (args) => ({
        url: `/phones/${args.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["PHONE"],
    }),
  }),
});

export default api;
