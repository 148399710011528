import React, { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import {
  Alert,
  Button,
  Card,
  Container,
  Form,
  ListGroup,
  Modal,
  Nav,
  Navbar,
  Spinner,
  Tab,
  Tabs,
} from "react-bootstrap";
import dayjs from "dayjs";
import classNames from "classnames";

import { changeUserToken } from "./useUserToken";
import { isActionError, PhoneInfo } from "./action";
import api from "./api";
import kakaoApi from "./kakaoApi";
import { Device, DeviceType, isGateway, isSensor2, isSensor3 } from "./type";
import { BatteryLevel_Text, DeviceType_Text, SensorEvent_Text } from "./texts";
import {
  GATEWAY2_SERIAL_PREFIX,
  GATEWAY3_SERIAL_PREFIX,
  SENSOR2_SERIAL_PREFIX,
  SENSOR3_SERIAL_PREFIX,
} from "./constant";
import { useForm } from "react-hook-form";

const DeviceHistoryModal = (props: { device: Device; onClose: () => void }) => {
  const [hiding, setHiding] = useState(false);

  const { data, isLoading } = api.endpoints.getDeviceHistory.useQuery(
    {
      serial: props.device.serial,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  return (
    <Modal
      centered
      show={!hiding}
      onHide={() => {
        setHiding(true);
        props.onClose();
      }}
    >
      <Modal.Header closeButton>기기 이력</Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <Spinner animation="border" />
        ) : (
          <ListGroup className="list-group-flush">
            {data?.map((item) => (
              <ListGroup.Item key={item.id}>
                <p className="mb-1">
                  <strong
                    className={classNames("p-1", {
                      "bg-secondary": item.event === "close",
                      "text-white": item.event === "close",
                      "bg-warning": item.event === "open",
                    })}
                  >
                    {SensorEvent_Text[item.event]}
                  </strong>{" "}
                  을 감지했습니다.
                </p>
                <small>{dayjs(item.datetime).format("YYYY-MM-DD ddd HH:mm:ss")}</small>
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </Modal.Body>
    </Modal>
  );
};

const DeviceHistoryShowButton = ({ device }: { device: Device }) => {
  const [show, setShow] = useState(false);

  const handleClick = () => {
    setShow(true);
  };

  const handleClose = useCallback(() => {
    setTimeout(() => {
      setShow(false);
    }, 300);
  }, []);

  return (
    <>
      <Button variant="info" onClick={handleClick} size="sm">
        이력 보기
      </Button>
      {show && <DeviceHistoryModal device={device} onClose={handleClose} />}
    </>
  );
};

const DeviceNameEditButton = (props: { device: Device }) => {
  const { device } = props;

  const formRef = useRef<HTMLFormElement>(undefined as unknown as HTMLFormElement);

  const [show, setShow] = useState(false);
  const [name, setName] = useState(props.device.name);

  useEffect(() => {
    setName(device.name);
  }, [device.name, show]);

  const [edit, { isLoading }] = api.endpoints.editDeviceName.useMutation();

  const handleClick = () => {
    formRef.current.requestSubmit();
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      await edit({ serial: device.serial, name }).unwrap();
      alert("변경 성공");
      setShow(false);
    } catch {
      return alert("알 수 없는 에러가 발생했습니다. 다시 시도해주세요.");
    }
  };

  return (
    <>
      <Button variant="secondary" size="sm" onClick={() => setShow(true)}>
        이름변경
      </Button>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        keyboard={!isLoading}
        backdrop={isLoading ? "static" : true}
        centered
      >
        <Modal.Header closeButton>기기 이름변경</Modal.Header>
        <Modal.Body>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>
                {DeviceType_Text[device.type]}
                {device.version} ({device.serial})
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={device.name}
                value={name}
                autoFocus
                required
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={name === device.name} onClick={handleClick}>
            변경
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const DeviceRemoveButton = (props: { device: Device }) => {
  const { device } = props;

  const [show, setShow] = useState(false);

  const [remove, { isLoading }] = api.endpoints.removeDevice.useMutation();

  return (
    <>
      <Button variant="danger" size="sm" onClick={() => setShow(true)}>
        제거
      </Button>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        keyboard={!isLoading}
        backdrop={isLoading ? "static" : true}
        centered
      >
        <Modal.Header closeButton>기기 제거</Modal.Header>
        <Modal.Body>
          <p>
            {DeviceType_Text[device.type]}
            {device.version} ({device.serial})
          </p>
          <p>
            이름: <strong>{device.name}</strong>
          </p>
          <p>정말 제거하나요?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() =>
              remove({ serial: device.serial })
                .unwrap()
                .then(() => alert("제거완료"))
            }
          >
            제거
          </Button>
          <Button variant="secondary" onClick={() => setShow(false)}>
            취소
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const DeviceCardTemplate = (props: { device: Device; children?: ReactNode }) => {
  const { device } = props;
  return (
    <Card className="mb-3">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <span>
          {DeviceType_Text[device.type]}
          {device.version}
        </span>
        <div className="d-flex gap-1">
          {device.type === "sensor" && <DeviceHistoryShowButton device={device} />}
          <DeviceNameEditButton device={device} />
          <DeviceRemoveButton device={device} />
        </div>
      </Card.Header>
      <Card.Body>
        <Card.Title>{device.name}</Card.Title>
        <Card.Subtitle className="text-muted">{device.serial}</Card.Subtitle>
      </Card.Body>
      {props.children}
    </Card>
  );
};

const DeviceCard = ({ device }: { device: Device }) => {
  if (isGateway(device)) {
    return (
      <DeviceCardTemplate device={device}>
        <ListGroup className="list-group-flush">
          <ListGroup.Item>
            연결상태: <strong>{device.isConnected ? "정상" : "연결끊김"}</strong>
          </ListGroup.Item>
        </ListGroup>
      </DeviceCardTemplate>
    );
  }

  if (isSensor2(device)) {
    return (
      <DeviceCardTemplate device={device}>
        <ListGroup className="list-group-flush">
          <ListGroup.Item>
            연결상태: <strong>{device.isDisconnected ? "연결끊김" : "정상"}</strong>
          </ListGroup.Item>
          <ListGroup.Item>
            열림상태: <strong>{device.isOpen ? "열림" : "닫힘"}</strong>
          </ListGroup.Item>
          <ListGroup.Item>
            알림: <strong>{device.isMute ? "무음" : "소리"}</strong>
          </ListGroup.Item>
        </ListGroup>
      </DeviceCardTemplate>
    );
  }

  if (isSensor3(device)) {
    return (
      <DeviceCardTemplate device={device}>
        <ListGroup className="list-group-flush">
          <ListGroup.Item>
            연결상태: <strong>{device.isDisconnected ? "연결끊김" : "정상"}</strong>
          </ListGroup.Item>
          <ListGroup.Item>
            열림상태: <strong>{device.isOpen ? "열림" : "닫힘"}</strong>
          </ListGroup.Item>
          <ListGroup.Item>
            알림: <strong>{device.isMute ? "무음" : "소리"}</strong>
          </ListGroup.Item>
          <ListGroup.Item>
            베터리: <strong>{BatteryLevel_Text[device.batteryLevel]}</strong>
          </ListGroup.Item>
        </ListGroup>
      </DeviceCardTemplate>
    );
  }

  return null;
};

const DeviceAddModal = (props: { groupId: string; onHide: () => void }) => {
  const [add, { isLoading }] = api.endpoints.addDevice.useMutation();

  const [serial, setSerial] = useState("");
  const [name, setName] = useState("");

  const formRef = useRef<HTMLFormElement>(undefined as unknown as HTMLFormElement);

  const handleClick = () => {
    formRef.current.requestSubmit();
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      await add({
        groupId: props.groupId,
        name,
        serial,
      }).unwrap();
      alert("등록 성공");
      props.onHide?.();
    } catch (error) {
      if (isActionError(error)) {
        if (error.code === "404") {
          return alert("존재하지 않는 시리얼입니다.");
        } else if (error.code === "409") {
          return alert("다른 사용자에게 이미 등록된 기기입니다.");
        }
      }
      return alert("알 수 없는 에러가 발생했습니다. 다시 시도해주세요.");
    }
  };

  let deviceType: DeviceType | null = null;
  let deviceVersion: 2 | 3 | null = null;
  switch (true) {
    case serial.startsWith(SENSOR2_SERIAL_PREFIX):
      deviceType = "sensor";
      deviceVersion = 2;
      break;
    case serial.startsWith(SENSOR3_SERIAL_PREFIX):
      deviceType = "sensor";
      deviceVersion = 3;
      break;
    case serial.startsWith(GATEWAY2_SERIAL_PREFIX):
      deviceType = "gateway";
      deviceVersion = 2;
      break;
    case serial.startsWith(GATEWAY3_SERIAL_PREFIX):
      deviceType = "gateway";
      deviceVersion = 3;
      break;
  }

  return (
    <Modal show onHide={props.onHide} keyboard={!isLoading} backdrop={isLoading ? "static" : true} centered>
      <Modal.Header closeButton>기기 추가</Modal.Header>
      <Modal.Body>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>시리얼</Form.Label>
            <Form.Control
              name="serial"
              type="text"
              placeholder="시리얼을 입력해주세요."
              value={serial}
              autoFocus
              required
              onChange={(e) => setSerial(e.target.value.toUpperCase())}
            />
          </Form.Group>
          {deviceType && (
            <>
              <Form.Group className="mb-3">
                <Form.Label>기기종류</Form.Label>
                <Form.Control
                  name="type"
                  type="text"
                  value={`${DeviceType_Text[deviceType]}${deviceVersion}`}
                  disabled
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>이름</Form.Label>
                <Form.Control
                  name="name"
                  type="text"
                  placeholder="이름을 입력해주세요."
                  value={name}
                  required
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>
            </>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={handleClick}
          disabled={isLoading}
          className="d-flex align-items-center gap-2"
        >
          {isLoading && <Spinner size="sm" />}
          추가
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const DeviceAddButton = (props: { groupId: string }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Button onClick={() => setShow(true)}>기기 추가</Button>

      {show && <DeviceAddModal groupId={props.groupId} onHide={() => setShow(false)} />}
    </>
  );
};

const PhoneRemoveButton = (props: { phone: PhoneInfo[number] }) => {
  const { phone } = props;

  const [show, setShow] = useState(false);

  const [remove, { isLoading }] = kakaoApi.endpoints.remove.useMutation();

  return (
    <>
      <Button variant="warning" size="sm" onClick={() => setShow(true)}>
        제거하기
      </Button>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        keyboard={!isLoading}
        backdrop={isLoading ? "static" : true}
        centered
      >
        <Modal.Header closeButton>번호 제거</Modal.Header>
        <Modal.Body>
          <p>
            번호: <strong>{phone.phone}</strong>
          </p>
          <p>정말 제거하나요?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() =>
              remove({ id: phone.id })
                .unwrap()
                .then(() => alert("제거완료"))
            }
          >
            제거
          </Button>
          <Button variant="secondary" onClick={() => setShow(false)}>
            취소
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const PhoneCard = (props: { phone: PhoneInfo[number] }) => {
  return (
    <Card className="mb-3">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <span>{props.phone.phone}</span>
        <div className="d-flex gap-1">
          <PhoneRemoveButton phone={props.phone} />
        </div>
      </Card.Header>
      <Card.Body>
        <Card.Text>등록일: {dayjs(props.phone.created_datetime).format("YYYY-MM-DD ddd HH:mm:ss")}</Card.Text>
      </Card.Body>
    </Card>
  );
};

const AddPhoneModal = (props: { onHide: () => void }) => {
  const [add] = kakaoApi.endpoints.add.useMutation();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<{ phone: string }>();

  const onSubmit = handleSubmit(async (data) => {
    try {
      await add({ phone: data.phone }).unwrap();
      alert("추가 완료");
      props.onHide();
    } catch (e) {
      alert("추가 실패");
    }
  });

  return (
    <Modal show onHide={props.onHide} keyboard={!isSubmitting} backdrop={isSubmitting ? "static" : true} centered>
      <Modal.Header closeButton>기기 추가</Modal.Header>
      <Modal.Body>
        <Alert variant="danger">현재는 본인 핸드폰인지 확인하지 않습니다. 입력에 주의바랍니다.</Alert>
        <Form onSubmit={onSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>핸드폰 번호 (숫자만 입력하세요)</Form.Label>
            <Form.Control
              type="text"
              placeholder="핸드폰 번호를 입력해주세요."
              autoFocus
              {...register("phone", {
                required: "핸드폰 번호를 입력해주세요.",
                validate: {
                  onlyNumber: (value) => !isNaN(Number(value)) || "숫자만 입력해주세요.",
                  pattern: (value) => /^010\d{7,8}$/.test(value) || "올바르지 않는 핸드폰 번호입니다.",
                },
              })}
            />
            {errors.phone && <Form.Text className="text-danger">{errors.phone.message}</Form.Text>}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={onSubmit}
          variant="primary"
          disabled={isSubmitting}
          className="d-flex align-items-center gap-2"
        >
          {isSubmitting && <Spinner size="sm" />}
          추가
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const AddPhoneButton = () => {
  const [show, setShow] = useState(false);

  const handleClick = useCallback(() => {
    setShow(true);
  }, []);

  const handleClose = useCallback(() => {
    setTimeout(() => {
      setShow(false);
    }, 300);
  }, []);
  return (
    <>
      <Button variant="primary" onClick={handleClick}>
        핸드폰 추가하기
      </Button>
      {show && <AddPhoneModal onHide={handleClose} />}
    </>
  );
};

const KakaoList = () => {
  const { data: phones, isLoading, isSuccess, isError } = kakaoApi.endpoints.list.useQuery();

  return (
    <>
      <Alert variant="info">핸드폰을 추가하면 센서 열림/닫힘 알림을 카카오톡으로 전송합니다.</Alert>

      <div className="d-flex gap-1 mb-3">
        <AddPhoneButton />
      </div>
      {isLoading && <Spinner animation="border" role="status" />}
      {isSuccess && phones?.map((phone) => <PhoneCard key={phone.id} phone={phone} />)}
      {isSuccess && phones?.length === 0 && <Alert variant="warning">등록된 핸드폰이 없습니다.</Alert>}
      {isError && <Alert variant="danger">목록을 불러오는데 오류가 발생했습니다.</Alert>}
    </>
  );
};

const DeviceList = (props: { groupIds: ReadonlyArray<string> }) => {
  const { data, isLoading, isError, error, refetch } = api.endpoints.getDevices.useQuery(
    { ids: props.groupIds },
    {
      pollingInterval: 10000,
    }
  );

  if (isLoading) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">로딩중...</span>
      </Spinner>
    );
  }

  if (isError && error) {
    throw error;
  }

  const devices = data as NonNullable<typeof data>;

  return (
    <>
      <Alert variant="warning">기기 상태가 실시간으로 반영되지 않습니다. 5초에 한번씩 자동으로 새로고침됩니다.</Alert>
      <div className="d-flex gap-1 mb-3">
        <DeviceAddButton groupId={props.groupIds[0]} />
        <RefreshButton refetch={refetch} />
      </div>
      {devices.map((device) => (
        <DeviceCard key={device.serial} device={device} />
      ))}
    </>
  );
};

const RefreshButton = (props: { refetch: () => void }) => {
  return <Button onClick={props.refetch}>새로고침</Button>;
};

const LogoutButton = () => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Button variant="light" onClick={() => setShow(true)}>
        로그아웃
      </Button>

      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header closeButton>로그아웃</Modal.Header>
        <Modal.Body>
          <p>정말 로그아웃하나요?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => changeUserToken(null)}>예</Button>
          <Button variant="secondary" onClick={() => setShow(false)}>
            아니요
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const MainPage = () => {
  const { data, isLoading, isError, error } = api.endpoints.getGroups.useQuery();
  const [createGroup] = api.endpoints.createGroup.useMutation();

  const groups = data as NonNullable<typeof data>;

  useEffect(() => {
    if (groups && groups.length === 0) {
      createGroup();
    }
  }, [createGroup, groups]);

  if (isLoading) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">로딩중...</span>
      </Spinner>
    );
  }

  if (isError && error) {
    throw error;
  }

  const groupIds = groups.map((group) => group.id);

  if (groupIds.length === 0) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">기본 그룹 생성중...</span>
      </Spinner>
    );
  }

  return (
    <>
      <Navbar bg="light">
        <Container>
          <Navbar.Brand>Winguard 임시 웹앱</Navbar.Brand>
          <Nav>
            <Nav.Item>
              <LogoutButton />
            </Nav.Item>
          </Nav>
        </Container>
      </Navbar>
      <Container className="py-3">
        <Tabs className="mb-3" defaultActiveKey="device">
          <Tab eventKey="device" title="기기목록">
            <DeviceList groupIds={groupIds} />
          </Tab>
          <Tab eventKey="alarm" title="알림(카카오톡)">
            <KakaoList />
          </Tab>
        </Tabs>
      </Container>
    </>
  );
};

export default withErrorBoundary(MainPage, {
  fallback: <div>알 수 없는 에러가 발생했습니다.</div>,
  onError(error) {
    if (isActionError(error) && error.statusCode === 401) {
      changeUserToken(null);
    }
  },
});
